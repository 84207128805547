<template>
  <tr>
    <td class="text-center">
      <VBtn
        @click="openUpdateDialog(cell.uuid)"
        small color="primary"
        class="btn-edit"
        :class="{sm : !isLarge}"
      >
      <VIcon dense >mdi-pencil</VIcon>
      </VBtn>
      <!-- <VIcon color="red" @click="deleteDialog = true">mdi-delete</VIcon> -->
    </td>
    <td class="text-center">
      <VIcon :size="isLarge ? '40' : '30'">mdi-table-merge-cells</VIcon>
    </td>
    <td>{{ cell.name }}</td>
    <td>{{ cell.ns_code }}</td>
    <td>{{ cell.parent_details.name || '--' }}</td>
    <td>{{ getSectorDetails() }}</td>

    <UpdateCellDialog
      :visible="updateDialog"
      @close="updateDialog = false"
      :cell="cell"
      :sectors="sectors"
      :items="items"
      v-if="updateDialog"
    />
    <DeleteAddressDialog
      :visible="deleteDialog"
      :uuid="cell.uuid"
      @close="deleteDialog = false"
      @delete="
      $emit('delete', cell.uuid);
      deleteDialog = false;
      "
    />
  </tr>
</template>

<script>
import UpdateCellDialog from './EditCellDialog.vue';
import DeleteAddressDialog from '../dialog/ConfirmDeleteDialog.vue';
import depotService from '../../services/request/depot/depotService';
import SizeUi from '../../mixins/SizeUi';

export default {
  name: 'CellRow',
  mixins: [SizeUi],
  props: {
    cells: {
      require: true,
    },
    cell: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  components: {
    UpdateCellDialog,
    DeleteAddressDialog,
  },
  data: () => ({
    updateDialog: false,
    deleteDialog: false,
    sectors: [],
    items: [],
  }),
  methods: {
    async openUpdateDialog(cell) {
      try {
        this.sectors = await depotService.getSectorsList();
        this.items = await depotService.getAllItems({
          uuid_cell: this.cell.uuid,
          groupby: 'option_details.name',
        });
        this.updateDialog = true;
        this.$emit('toggleRow', cell);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    getSectorDetails() {
      return this.cell?.sector_details?.name;
    },
  },
  computed: {
    background() {
      if (this.index % 2 === 0) {
        return '#F8F8F8';
      }
      return 'white';
    },
  },
};
</script>

<style scoped lang="scss">
.td {
  height: 40px;
  padding: 4px 16px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #676767;
  text-align: left;
  word-break: break-all;
  height: auto;
  border-left: 1px solid #f2f2f2;
  &:last-child {
    padding: 4px;
    border-right: 1px solid #f2f2f2;
  }
}
.ico {
  padding: 0 !important;
}
</style>
